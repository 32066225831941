<template>
  <v-container>
    <v-row justify="space-around">
      <v-col>
        <div class="text-h5">Total</div>
        <v-simple-table v-bind:style="tableStyle">
          <template v-slot:default>
            <thead>
              <tr class="grey lighten-3">
                <th class="text-center" width="33.333%">重量（ kg ）</th>
                <th class="text-center" width="33.333%">総額（ ＄ ）</th>
                <th class="text-center" width="33.333%">
                  {{ `補償額（ ${item.courierId === '1' ? '＄' : '￥'} ）` }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  <v-text-field
                    type="number"
                    min="0"
                    v-if="editing && editing.pk === item.pk"
                    filled
                    dense
                    hide-details="true"
                    :value="item.totalWeight"
                    @change="
                      changeDraft({
                        key: 'totalWeight',
                        value: $event,
                        pk: item.pk,
                      })
                    "
                  ></v-text-field>
                  <span v-else>{{ item.totalWeight ? item.totalWeight.toLocaleString() : '-' }}</span>
                </td>
                <td class="text-center">
                  <v-text-field
                    type="number"
                    min="0"
                    v-if="editing && editing.pk === item.pk"
                    filled
                    dense
                    hide-details="true"
                    :value="item.totalPrice"
                    @change="
                      changeDraft({
                        key: 'totalPrice',
                        value: $event,
                        pk: item.pk,
                      })
                    "
                  ></v-text-field>
                  <span v-else>{{ item.totalPrice ? item.totalPrice.toLocaleString() : '-' }}</span>
                </td>
                <td class="text-center">
                  <v-text-field
                    type="number"
                    min="0"
                    v-if="editing && editing.pk === item.pk"
                    filled
                    dense
                    hide-details="true"
                    :value="item.totalCompensation"
                    @change="
                      changeDraft({
                        key: 'totalCompensation',
                        value: $event,
                        pk: item.pk,
                      })
                    "
                  ></v-text-field>
                  <span v-else>{{ item.totalCompensation ? item.totalCompensation.toLocaleString() : '-' }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col v-if="item.areaCode != item.countryCode">
        <div class="text-h5">Individual</div>
        <v-simple-table v-bind:style="tableStyle">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center" width="33.333%">重量（ kg ）</th>
                <th class="text-center" width="33.333%">総額（ ＄ ）</th>
                <th class="text-center" width="33.333%">
                  {{ `補償額（ ${item.courierId === '1' ? '＄' : '￥'} ）` }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  <v-text-field
                    type="number"
                    min="0"
                    v-if="editing && editing.pk === item.pk"
                    filled
                    dense
                    hide-details="true"
                    :value="item.individualWeight"
                    @change="
                      changeDraft({
                        key: 'individualWeight',
                        value: $event,
                        pk: item.pk,
                      })
                    "
                  ></v-text-field>
                  <span v-else>{{ item.individualWeight ? item.individualWeight.toLocaleString() : '-' }}</span>
                </td>
                <td class="text-center">
                  <v-text-field
                    type="number"
                    min="0"
                    v-if="editing && editing.pk === item.pk"
                    filled
                    dense
                    hide-details="true"
                    :value="item.individualPrice"
                    @change="
                      changeDraft({
                        key: 'individualPrice',
                        value: $event,
                        pk: item.pk,
                      })
                    "
                  ></v-text-field>
                  <span v-else>{{ item.individualPrice ? item.individualPrice.toLocaleString() : '-' }}</span>
                </td>
                <td class="text-center">
                  <v-text-field
                    type="number"
                    min="0"
                    v-if="editing && editing.pk === item.pk"
                    filled
                    dense
                    hide-details="true"
                    :value="item.individualCompensation"
                    @change="
                      changeDraft({
                        key: 'individualCompensation',
                        value: $event,
                        pk: item.pk,
                      })
                    "
                  ></v-text-field>
                  <span v-else>{{
                    item.individualCompensation ? item.individualCompensation.toLocaleString() : '-'
                  }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="1" class="pt-10 d-flex justify-center align-center">
        <div v-if="role">
          <!-- startEditing -->
          <v-btn v-if="!editing || editing.pk !== item.pk" text icon color="blue lighten-2" @click="startEditing(item)">
            <v-icon large>mdi-pencil-box</v-icon>
          </v-btn>
          <!-- saveBoxType -->
          <v-btn v-else text icon color="green lighten-2" @click="apiPutCourierLimit(item)">
            <v-icon large>mdi-content-save</v-icon>
          </v-btn>
          <!-- cancelEditing -->
          <v-btn v-if="editing && editing.pk === item.pk" text icon color="red lighten-2" @click="cancelEditing()"
            ><v-icon large>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  props: {
    item: Object,
    role: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableStyle: { border: 'thin solid #0004', borderRadius: '4px' },
    };
  },
  computed: {
    ...mapGetters({
      editing: 'courierLimitMaster/editing',
    }),
  },
  methods: {
    ...mapActions({ apiPutCourierLimit: 'api/putCourierLimit' }),
    ...mapMutations({
      changeDraft: 'courierLimitMaster/changeDraft',
      startEditing: 'courierLimitMaster/startEditing',
      cancelEditing: 'courierLimitMaster/cancelEditing',
    }),
  },
};
</script>

<style scoped>
.theme--light.v-data-table :deep() thead th {
  background: rgb(224, 224, 224) !important;
  white-space: pre;
  top: 0;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
  /* position: sticky;
  z-index: 3; */
}
.theme--dark.v-data-table :deep() thead th {
  background: rgba(0, 43, 91, 1) !important;
  white-space: pre;
  top: 0;
  box-shadow: inset 0 -1px 0 rgb(255 255 255 / 12%);
  /* position: sticky;
  z-index: 3; */
}
</style>
