<template>
  <div>
    <v-card-actions class="justify-end px-3">
      <v-dialog v-if="role" v-model="dialog" width="500" persistent>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary mr-4" v-bind="attrs" v-on="on">CSV<v-icon>mdi-upload</v-icon> </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 white--text info">CSVアップロード</v-card-title>
          <div v-if="!completionNotice" class="d-flex flex-column justify-center align-center pa-8">
            <v-btn color="primary" outlined @click="$refs.fileRef.click()"
              >{{ !file ? 'CSVファイルを選択' : file.name }}<v-icon>mdi-upload</v-icon>
            </v-btn>
            <input type="file" required style="display: none" @change="setCsvFile($event)" ref="fileRef" />
            <span
              v-if="file && file.errors.length !== 0"
              v-html="htmlText(file.errors.slice(0, 3).join('\n'))"
              class="pt-4 red--text"
            >
            </span>
          </div>

          <v-card-text v-if="completionNotice" class="pt-4"> アップロードが完了しました </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="file && !completionNotice"
              :disabled="file.errors.length !== 0"
              color="primary"
              @click="
                apiPutCourierLimit(file.data);
                $refs.fileRef.value = '';
              "
              >アップロード</v-btn
            >
            <v-btn v-if="completionNotice" @click="handleClose" color="info" text> OK </v-btn>
            <v-btn v-else @click="handleClose" color="warning" text>キャンセル</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn @click="downloadCsvFile(list)" :disabled="list.length === 0"
        >CSV
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expansion-panels multiple>
      <v-expansion-panel v-for="(courier, ci) in nested" :key="ci">
        <v-expansion-panel-header>
          <!-- v-if="courier[0][+ci].countryCode === 'FEDEX'" -->
          {{ courier[0][0].courierName }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <courier-limit-master-item
            :item="
              courier
                .flat()
                .find(
                  ({ courierId, countryCode, courierName }) => +courierId === +ci + 1 && countryCode === courierName,
                )
            "
            :role="role"
          ></courier-limit-master-item>
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="(region, ri) in courier" :key="ri">
              <v-expansion-panel-header v-if="region.find(({ region }) => region)">
                <template v-slot:default="{ open }">
                  {{ !open ? region[0].region : `${region[0].region} - ${region[0].courierName}` }}
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <courier-limit-master-item
                  :item="region.find((i) => i.areaCode === i.countryCode)"
                  :role="role"
                ></courier-limit-master-item>
                <v-expansion-panels multiple>
                  <v-expansion-panel v-for="(country, i) in region" :key="i">
                    <div v-if="country.areaCode != country.countryCode">
                      <v-expansion-panel-header>
                        <template v-slot:default="{ open }">
                          {{ !open ? country.countryName : `${country.countryName} - ${country.courierName}` }}
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <courier-limit-master-item :item="country" :role="role"></courier-limit-master-item>
                      </v-expansion-panel-content>
                    </div>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import _ from 'lodash';
import iconv from 'iconv-lite';
import csv from 'csvtojson';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CourierLimitMasterItem from './CourierLimitMasterItem.vue';

export default {
  components: { 'courier-limit-master-item': CourierLimitMasterItem },
  computed: {
    ...mapGetters({
      list: 'courierLimitMaster/list',
      completionNotice: 'courierLimitMaster/completionNotice',
      path: 'defined/pathNames',
      user: 'defined/user',
    }),
    nested() {
      return _(this.list)
        .groupBy('courierId')
        .map((c) =>
          _(c)
            .groupBy('region')
            .map((r) =>
              r
                .map((item) => {
                  item.pk = `${item.courierId}-${item.countryCode}`;
                  return item;
                })
                .sort((a, b) => (a.countryName < b.countryName ? -1 : 1)),
            )
            .value()
            .sort((a, b) => (a[0].region < b[0].region ? -1 : 1)),
        )
        .value()
        .sort((a, b) => (a[0][0].courierId < b[0][0].courierId ? -1 : 1));
    },
  },
  data() {
    return {
      dialog: false,
      file: null,
      role: false,
    };
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.COURIER_LIMIT_MASTER,
      authority: this.user.authority,
    });
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      apiListCourier: 'api/listCourier',
      downloadCsvFile: 'courierLimitMaster/downloadCsvFile',
      apiPutCourierLimit: 'api/putCourierLimit',
      getRole: 'common/getRole',
    }),
    ...mapMutations({
      closeNotice: 'courierLimitMaster/closeNotice',
    }),
    async initialize() {
      await this.apiListCourier();
    },
    downloadCsvFile() {
      //CSVデータ
      const header = {
        courierName: 'クーリエ',
        countryName: '国名',
        region: 'エリア名',
        totalWeight: 'Total重量(kg)',
        totalPrice: 'Total総額($)',
        totalCompensation: 'Total補償額(UPS & DHLは￥)',
        individualWeight: 'Individual重量(kg)',
        individualPrice: 'Individual総額($)',
        individualCompensation: 'Individual補償額(UPS & DHLは￥)',
      };
      const data =
        [header]
          .concat(this.list)
          .map((l) => {
            const cols = [
              l.courierName,
              l.countryName,
              l.region,
              l.totalWeight,
              l.totalPrice,
              l.totalCompensation,
              l.individualWeight,
              l.individualPrice,
              l.individualCompensation,
            ];
            return `"${cols.join('","')}"`;
          })
          .join('\r\n') + '\r\n';
      const shiftJIS = iconv.encode(data, 'Shift_JIS');
      const blob = new Blob([shiftJIS], { type: 'text/csv charset=Shift_JIS' });
      const url = window.URL.createObjectURL(blob);
      const download = document.createElement('a');
      download.href = url;
      download.download = 'courier_limit.csv';
      download.click();
      window.URL.revokeObjectURL(url);
    },
    handleClose() {
      this.dialog = false;
      window.setTimeout(() => {
        this.file = null;
        this.closeNotice();
      }, 300);
    },
    setCsvFile(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buf = Buffer.from(e.target.result, 'binary');
        const csvString = iconv.decode(buf, 'Shift_JIS');
        const headers = [
          'courierName',
          'countryName',
          'region',
          'totalWeight',
          'totalPrice',
          'totalCompensation',
          'individualWeight',
          'individualPrice',
          'individualCompensation',
        ];
        const json = await csv({ headers, noheader: false }).fromString(csvString);
        const equal = (a, b) => {
          if (a.courierName.toUpperCase() !== b.courierName.toUpperCase()) return false;
          return a.countryName === b.countryName;
        };
        const errors = [];
        const data = json.map((next, i) => {
          const current = this.list.find((current) => equal(current, next));
          if (!current) {
            errors.push(`${i + 1}行目のクーリエ名または国名が不正です`);
            return null;
          }
          const result = {};
          result.courierId = current.courierId;
          result.countryCode = current.countryCode;
          result.index = i + 1;
          Object.keys(next).forEach((key) => {
            key;
            result[key] = next[key] !== '' ? next[key] : null;
          });
          return result;
        });
        this.file = { name: file.name, data, errors };
      };
      reader.readAsBinaryString(file);
    },
    htmlText(msg) {
      return msg?.replace(/\r?\n/g, '<br>');
    },
  },
};
</script>
