var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-col',[_c('div',{staticClass:"text-h5"},[_vm._v("Total")]),_c('v-simple-table',{style:(_vm.tableStyle),scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"grey lighten-3"},[_c('th',{staticClass:"text-center",attrs:{"width":"33.333%"}},[_vm._v("重量（ kg ）")]),_c('th',{staticClass:"text-center",attrs:{"width":"33.333%"}},[_vm._v("総額（ ＄ ）")]),_c('th',{staticClass:"text-center",attrs:{"width":"33.333%"}},[_vm._v(" "+_vm._s(`補償額（ ${_vm.item.courierId === '1' ? '＄' : '￥'} ）`)+" ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[(_vm.editing && _vm.editing.pk === _vm.item.pk)?_c('v-text-field',{attrs:{"type":"number","min":"0","filled":"","dense":"","hide-details":"true","value":_vm.item.totalWeight},on:{"change":function($event){return _vm.changeDraft({
                      key: 'totalWeight',
                      value: $event,
                      pk: _vm.item.pk,
                    })}}}):_c('span',[_vm._v(_vm._s(_vm.item.totalWeight ? _vm.item.totalWeight.toLocaleString() : '-'))])],1),_c('td',{staticClass:"text-center"},[(_vm.editing && _vm.editing.pk === _vm.item.pk)?_c('v-text-field',{attrs:{"type":"number","min":"0","filled":"","dense":"","hide-details":"true","value":_vm.item.totalPrice},on:{"change":function($event){return _vm.changeDraft({
                      key: 'totalPrice',
                      value: $event,
                      pk: _vm.item.pk,
                    })}}}):_c('span',[_vm._v(_vm._s(_vm.item.totalPrice ? _vm.item.totalPrice.toLocaleString() : '-'))])],1),_c('td',{staticClass:"text-center"},[(_vm.editing && _vm.editing.pk === _vm.item.pk)?_c('v-text-field',{attrs:{"type":"number","min":"0","filled":"","dense":"","hide-details":"true","value":_vm.item.totalCompensation},on:{"change":function($event){return _vm.changeDraft({
                      key: 'totalCompensation',
                      value: $event,
                      pk: _vm.item.pk,
                    })}}}):_c('span',[_vm._v(_vm._s(_vm.item.totalCompensation ? _vm.item.totalCompensation.toLocaleString() : '-'))])],1)])])]},proxy:true}])})],1),(_vm.item.areaCode != _vm.item.countryCode)?_c('v-col',[_c('div',{staticClass:"text-h5"},[_vm._v("Individual")]),_c('v-simple-table',{style:(_vm.tableStyle),scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",attrs:{"width":"33.333%"}},[_vm._v("重量（ kg ）")]),_c('th',{staticClass:"text-center",attrs:{"width":"33.333%"}},[_vm._v("総額（ ＄ ）")]),_c('th',{staticClass:"text-center",attrs:{"width":"33.333%"}},[_vm._v(" "+_vm._s(`補償額（ ${_vm.item.courierId === '1' ? '＄' : '￥'} ）`)+" ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[(_vm.editing && _vm.editing.pk === _vm.item.pk)?_c('v-text-field',{attrs:{"type":"number","min":"0","filled":"","dense":"","hide-details":"true","value":_vm.item.individualWeight},on:{"change":function($event){return _vm.changeDraft({
                      key: 'individualWeight',
                      value: $event,
                      pk: _vm.item.pk,
                    })}}}):_c('span',[_vm._v(_vm._s(_vm.item.individualWeight ? _vm.item.individualWeight.toLocaleString() : '-'))])],1),_c('td',{staticClass:"text-center"},[(_vm.editing && _vm.editing.pk === _vm.item.pk)?_c('v-text-field',{attrs:{"type":"number","min":"0","filled":"","dense":"","hide-details":"true","value":_vm.item.individualPrice},on:{"change":function($event){return _vm.changeDraft({
                      key: 'individualPrice',
                      value: $event,
                      pk: _vm.item.pk,
                    })}}}):_c('span',[_vm._v(_vm._s(_vm.item.individualPrice ? _vm.item.individualPrice.toLocaleString() : '-'))])],1),_c('td',{staticClass:"text-center"},[(_vm.editing && _vm.editing.pk === _vm.item.pk)?_c('v-text-field',{attrs:{"type":"number","min":"0","filled":"","dense":"","hide-details":"true","value":_vm.item.individualCompensation},on:{"change":function($event){return _vm.changeDraft({
                      key: 'individualCompensation',
                      value: $event,
                      pk: _vm.item.pk,
                    })}}}):_c('span',[_vm._v(_vm._s(_vm.item.individualCompensation ? _vm.item.individualCompensation.toLocaleString() : '-'))])],1)])])]},proxy:true}],null,false,524294065)})],1):_vm._e(),_c('v-col',{staticClass:"pt-10 d-flex justify-center align-center",attrs:{"cols":"1"}},[(_vm.role)?_c('div',[(!_vm.editing || _vm.editing.pk !== _vm.item.pk)?_c('v-btn',{attrs:{"text":"","icon":"","color":"blue lighten-2"},on:{"click":function($event){return _vm.startEditing(_vm.item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-pencil-box")])],1):_c('v-btn',{attrs:{"text":"","icon":"","color":"green lighten-2"},on:{"click":function($event){return _vm.apiPutCourierLimit(_vm.item)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-content-save")])],1),(_vm.editing && _vm.editing.pk === _vm.item.pk)?_c('v-btn',{attrs:{"text":"","icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.cancelEditing()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close-circle")])],1):_vm._e()],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }